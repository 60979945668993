import React from 'react';

import backgroundImage from '../../assets/background/bg_success.svg';
import iconImage from '../../assets/logo.png';
import './styles.css';

function Success() {
  return (
    <div className='container center'>
      <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
        <h1 style={{ marginBottom: 40 }}>Cadastro efetuado com sucesso!</h1>
        <img style={{
          width: '60%',
          height: 'auto'
        }} src={backgroundImage} alt="Dog's Ville | Aqui seu cão vem sempre em primeiro lugar!" />
        <img className='icon-right' src={iconImage} alt="Dog's Ville | Aqui seu cão vem sempre em primeiro lugar!" />
      </div>
    </div>
  )
}

export default Success;
