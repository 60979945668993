import React from 'react';

import backgroundImage from '../../assets/background/bg_not_found.svg';
import iconImage from '../../assets/logo.png';
import './styles.css';

function NotFound() {
  return (
    <div className='container center'>
      <img style={{
        width: '100%',
        height: 'auto'
      }} src={backgroundImage} alt="Dog's Ville | Aqui seu cão vem sempre em primeiro lugar!" />
      <img className='icon-right' src={iconImage} alt="Dog's Ville | Aqui seu cão vem sempre em primeiro lugar!" />
    </div>
  )
}

export default NotFound;
