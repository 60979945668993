import React, { useState, useEffect } from 'react';
import { Formik, Form, Field, FieldArray, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import DateFnsUtils from '@date-io/date-fns'
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { useHistory, useParams } from 'react-router-dom';
import MaskedInput from 'react-text-mask'
import { ptBR } from "date-fns/locale";

import logo from '../../assets/logo-horizontal.png'
import imgDefault from '../../assets/sem-foto.jpg'
import api from '../../services/api'
import { phoneNumberMask, cpfMask, cepMask, weightMask } from '../../shared/utils/mask'

import "./styles.css"
import { toast, ToastContainer } from 'react-toastify';

const FormikDatePicker = ({
  profile,
  i,
  name,
  form: { setFieldValue },
  field: { value },
  ...rest
}) => {
  return (
    <KeyboardDatePicker
      fullWidth
      autoOk
      variant="inline"
      format="dd/MM/yyyy"
      value={value}
      onChange={(value) => {
        profile === 1 ? setFieldValue(`pets.${i}.birthDate`, value) : setFieldValue(`birth`, value);
      }}
    />
  );
};

function Home() {
  const [racas, setRacas] = useState();
  const [loading, setLoading] = useState(false);
  const [handleForm, setHandleForm] = useState(true);
  const history = useHistory();
  const [empresa, setEmpresa] = useState({})

  const initialValues = {
    referal: '',
    numberOfPets: '',
    name: '',
    cpf: '',
    rg: '',
    sex: '',
    instagram: '',
    civil_status: '',
    email: '',
    birth: new Date(),
    celular: '',
    phone: '',
    cep: '',
    street: '',
    city: '',
    state: '',
    neighborhood: '',
    number: '',
    adjunct: '',
    pets: [],
  };

  useEffect(() => {
    getUrl();
    const { referal } = initialValues;
    if (referal == null || referal == undefined) {
      console.log('Sem referal', referal);
      history.push('/404');
    }
    api.get(`pets/racas/${referal}`)
      .then(res => {
        console.log(res);
        const { racas, empresa } = res.data;
        setRacas(racas);
        setEmpresa(empresa);
      })
      .catch(e => {
        history.push('/404');
        console.error(e);
      })

  }, [setRacas])

  const getUrl = async () => {

    const urlParams = new URLSearchParams(window.location.search);
    const params = urlParams.get("referal")

    initialValues.referal = params;
  }

  const validationSchema = Yup.object().shape({
    numberOfPets: Yup.string()
      .required('Número de pets é obrigatório'),
    name: Yup.string()
      .required('Nome do tutor é obrigatório'),
    cpf: Yup.string()
      .required('CPF é obrigatório'),
    email: Yup.string().email('Digite um e-mail válido')
      .required('E-mail obrigatório'),
    celular: Yup.string()
      .required('Celular obrigatório'),
    cep: Yup.string()
      .required('CEP obrigatório'),
    number: Yup.string()
      .required('Número obrigatório'),
    adjunct: Yup.string(),
    pets: Yup.array().of(
      Yup.object().shape({
        pet_name: Yup.string()
          .required('Nome do animal é obrigatório'),
        gender: Yup.string()
          .required('Sexo do animal é obrigatório'),
        specie: Yup.string()
          .required('Espécie do animal é obrigatório'),
        breed: Yup.string()
          .required('Raça do animal é obrigatório'),
        color: Yup.string()
          .required('Cor do animal é obrigatório'),
        trained: Yup.string()
          .required('Campo obrigatório'),
        castrated: Yup.string()
          .required('Campo obrigatório'),
        weight: Yup.string()
          .required('Peso é obrigatório'),
        gait: Yup.string()
          .required('Porte é obrigatório'),
        coat: Yup.string()
          .required('Pelagem é obrigatório')
      })
    )
  });

  function onChangeTickets(e, field, values, setValues) {
    // update dynamic form
    const pets = [...values.pets];
    const numberOfPets = e.target.value || 0;
    const previousNumber = parseInt(field.value || '0');
    if (previousNumber < numberOfPets) {
      for (let i = previousNumber; i < numberOfPets; i++) {
        pets.push({
          pet_name: '',
          nickname: '',
          gender: '',
          specie: '',
          breed: '',
          color: '',
          trained: '',
          castrated: '',
          weight: '',
          gait: '',
          coat: '',
          meal: '',
          mealRestriction: '',
          mealTime: '',
          mealAmount: '',
          birthDate: new Date(),
          vetName: '',
          vetCel: '',
          obs: '',
          imagePet: '',
          imageAuthorized: '1'
        });
      }
    } else {
      for (let i = previousNumber; i >= numberOfPets; i--) {
        pets.splice(i, 1);
      }
    }
    setValues({ ...values, pets });

    // call formik onChange method
    field.onChange(e);
  }


  async function onSubmit(fields) {
    try {
     const response = await api.post(`/pets/cadastro`, fields)

     if (response.status === 200 || response.status === 201) {
         history.push('/success');
     } else {
      alert(response.message)
     }
    } catch (error) {
        alert(error.response.data.message)
    }
  }

  function onBlurCep(ev, setFieldValue) {
    const { value } = ev.target;

    const cep = value?.replace(/[^0-9]/g, '');

    if (cep?.length !== 8) {
      return;
    }

    fetch(`https://viacep.com.br/ws/${cep}/json/`)
      .then((res) => res.json())
      .then((data) => {
        console.log('data', data)
        setFieldValue('city', data.localidade)
        setFieldValue('state', data.uf)
        setFieldValue('neighborhood', data.bairro)
        setFieldValue('adjunct', data.complemento)
        setFieldValue('street', data.logradouro)
      });
  }

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        />
      <ToastContainer />
      <div style={{
        maxWidth: 1366,
        margin: '0 auto',
        display: 'flex'
      }}>
        <img src={logo} alt="Dog's Ville | Cadastro" className="logoImg" />
      </div>

      <div style={{
        maxWidth: 1366,
        margin: '0 auto',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
      }}>

        <div style={{ margin: 20 }}>
          <h4>{empresa.empresa || 'Nome da empresa'}</h4>
          <b>Contato: </b>
          {empresa.contato || '(xx) xxxx-xxxx'}
        </div>
        {empresa?.logo?.endsWith('FOTOS/') ?
          <img src={imgDefault} alt="Dog's Ville | Cadastro" style={{
            width: '100%',
            margin: 20,
            maxWidth: 150,
            height: 'auto'
          }} /> :
          <img src={empresa?.logo} alt="Dog's Ville | Cadastro" style={{
            width: '100%',
            margin: 20,
            maxWidth: 150,
            height: 'auto'
          }} />

        }
      </div>
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
        <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
          {({ errors, values, touched, setValues, setFieldValue }) => (

            <Form style={{ display: 'flex', justifyContent: 'center' }}>
              <div className="card m-3" style={{ maxWidth: '1366px', flex: 1 }}>
                <div className="card-body border-bottom" >
                  <div className="form-row form-group row">

                    <div className="form-group col-xs-12 col-sm-4 col-md-3 " style={{ marginBottom: 20 }} >
                      <label style={{ marginRight: 4 }}>Nome do tutor*</label>
                      <Field

                        name={`name`} type="text"
                        className={'form-control' + (errors.name && touched.name ? ' is-invalid' : '')}
                      />
                      <ErrorMessage style={{ color: 'red', fontSize: 12, fontWeight: 500, letterSpacing: 0.5 }} name={`name`} component="div" className="invalid-feedback" />
                    </div>
                    <div className="form-group col-xs-12 col-sm-4 col-md-3 " style={{ marginBottom: 20 }} >
                      <label style={{ marginRight: 4 }}>CPF*</label>
                      <Field
                        name={`cpf`}>
                        {({ field }) => (
                          <MaskedInput
                            {...field}
                            mask={cpfMask}
                            id={`cpf`}
                            type="text"
                            className={'form-control' + (errors.cpf && touched.cpf ? ' is-invalid' : '')}
                          />
                        )}</Field>

                    </div>

                    <div className="form-group col-xs-12 col-sm-4 col-md-3 " style={{ marginBottom: 20 }} >
                      <label style={{ marginRight: 4 }}>RG</label>
                      <Field

                        name={`rg`} type="text"
                        className={'form-control' + (errors.rg && touched.rg ? ' is-invalid' : '')}
                      />
                      <ErrorMessage style={{ color: 'red', fontSize: 12, fontWeight: 500, letterSpacing: 0.5 }} name={`rg`} component="div" className="invalid-feedback" />
                    </div>

                    <div className="form-group col-xs-12 col-sm-4 col-md-3">
                      <label style={{ marginRight: 4 }}>Sexo</label>
                      <Field
                        name={`sex`} as="select"
                        className={'form-control' + (errors.sex && touched.sex ? ' is-invalid' : '')}>

                        <option value="">Selecione...</option>
                        <option value="F">Feminino</option>
                        <option value="M">Masculino</option>
                      </Field>
                      <ErrorMessage style={{ color: 'red', fontSize: 12, fontWeight: 500, letterSpacing: 0.5 }} name={`sex`} component="div" className="invalid-feedback" />
                    </div>

                    <div className="form-group col-xs-12 col-sm-4 col-md-3">
                      <label style={{ marginRight: 4 }}>Estado Civil</label>
                      <Field
                        name={`civil_status`} as="select"
                        className={'form-control' + (errors.civil_status && touched.civil_status ? ' is-invalid' : '')}>

                        <option value="">Selecione...</option>
                        <option value="Solteiro(a)">Solteiro(a)</option>
                        <option value="Casado(a)">Casado(a)</option>
                        <option value="Divorciado(a)">Divorciado(a)</option>
                        <option value="Viúvo(a)">Viúvo(a)</option>
                        <option value="Separado(a)">Separado(a)</option>
                      </Field>
                      <ErrorMessage style={{ color: 'red', fontSize: 12, fontWeight: 500, letterSpacing: 0.5 }} name={`civil_status`} component="div" className="invalid-feedback" />
                    </div>

                    <div className="form-group col-xs-12 col-sm-4 col-md-3" >
                      <label style={{ marginRight: 4 }}>Data de nascimento</label>
                      <Field
                        component={FormikDatePicker} name={`birth`} profile={0} />
                      <ErrorMessage
                        style={{ color: 'red', fontSize: 12, fontWeight: 500, letterSpacing: 0.5 }}
                        name={`birth`}
                        component="div"
                        className="invalid-feedback" />
                    </div>

                    <div className="form-group col-xs-12 col-sm-4 col-md-3 " style={{ marginBottom: 20 }} >
                      <label style={{ marginRight: 4 }}>E-mail*</label>
                      <Field
                        name={`email`} type="text"
                        className={'form-control' + (errors.email && touched.email ? ' is-invalid' : '')}
                      />
                      <ErrorMessage style={{ color: 'red', fontSize: 12, fontWeight: 500, letterSpacing: 0.5 }} name={`email`} component="div" className="invalid-feedback" />
                    </div>

                    <div className="form-group col-xs-12 col-sm-4 col-md-3" style={{ marginBottom: 20 }} >
                      <label style={{ marginRight: 4 }}>Instagram</label>
                      <Field
                        name={`instagram`} type="text"
                        className={'form-control' + (errors.instagram && touched.instagram ? ' is-invalid' : '')}
                      />
                      <ErrorMessage style={{ color: 'red', fontSize: 12, fontWeight: 500, letterSpacing: 0.5 }} name={`instagram`} component="div" className="invalid-feedback" />
                    </div>

                    <div className="form-group col-xs-12 col-sm-4 col-md-3 " style={{ marginBottom: 20 }} >
                      <label style={{ marginRight: 4 }}>Telefone*</label>
                      <Field
                        name={`celular`}>
                        {({ field }) => (
                          <MaskedInput

                            {...field}
                            mask={phoneNumberMask}
                            id={`celular`}
                            type="text"
                            className={'form-control' + (errors.celular && touched.celular ? ' is-invalid' : '')}
                          />
                        )}</Field>

                      <ErrorMessage style={{ color: 'red', fontSize: 12, fontWeight: 500, letterSpacing: 0.5 }} name={`celular`} component="div" className="invalid-feedback" />
                    </div>

                    <div className="form-group col-xs-12 col-sm-4 col-md-3 " style={{ marginBottom: 20 }} >
                      <label style={{ marginRight: 4 }}>CEP*</label>
                      <Field
                        name={`cep`}>
                        {({ field }) => (
                          <MaskedInput

                            {...field}
                            mask={cepMask}
                            id={`cep`}
                            type="text"
                            className={'form-control' + (errors.cep && touched.cep ? ' is-invalid' : '')}
                            onBlur={(ev) => onBlurCep(ev, setFieldValue)}
                          />
                        )}</Field>

                      <ErrorMessage style={{ color: 'red', fontSize: 12, fontWeight: 500, letterSpacing: 0.5 }} name={`celular`} component="div" className="invalid-feedback" />
                    </div>

                    <ErrorMessage style={{ color: 'red', fontSize: 12, fontWeight: 500, letterSpacing: 0.5 }} name={`cep`} component="div" className="invalid-feedback" />
                    <div className="form-group col-xs-12 col-sm-4 col-md-3 " style={{ marginBottom: 20 }} >
                      <label style={{ marginRight: 4 }}>Logradouro/Rua</label>
                      <Field
                        name={`street`} type="text"
                        className={'form-control' + (errors.street && touched.street ? ' is-invalid' : '')}
                      />
                      <ErrorMessage style={{ color: 'red', fontSize: 12, fontWeight: 500, letterSpacing: 0.5 }} name={`street`} component="div" className="invalid-feedback" />
                    </div>

                    <div className="form-group col-xs-12 col-sm-4 col-md-3 " style={{ marginBottom: 20 }} >
                      <label style={{ marginRight: 4 }}>Número*</label>
                      <Field
                        name={`number`} type="text"
                        className={'form-control' + (errors.number && touched.number ? ' is-invalid' : '')}
                      />
                      <ErrorMessage style={{ color: 'red', fontSize: 12, fontWeight: 500, letterSpacing: 0.5 }} name={`number`} component="div" className="invalid-feedback" />
                    </div>

                    <div className="form-group col-xs-12 col-sm-4 col-md-3 " style={{ marginBottom: 20 }} >
                      <label style={{ marginRight: 4 }}>Complemento</label>
                      <Field
                        name={`adjunct`} type="text"
                        className={'form-control' + (errors.adjunct && touched.adjunct ? ' is-invalid' : '')}
                      />
                      <ErrorMessage style={{ color: 'red', fontSize: 12, fontWeight: 500, letterSpacing: 0.5 }} name={`adjunct`} component="div" className="invalid-feedback" />
                    </div>

                    <div className="form-group col-xs-12 col-sm-4 col-md-3 " style={{ marginBottom: 20 }} >
                      <label style={{ marginRight: 4 }}>Cidade</label>
                      <Field
                        disabled
                        name={`city`} type="text"
                        className={'form-control' + (errors.city && touched.city ? ' is-invalid' : '')}
                      />
                      <ErrorMessage style={{ color: 'red', fontSize: 12, fontWeight: 500, letterSpacing: 0.5 }} name={`city`} component="div" className="invalid-feedback" />
                    </div>

                    <div className="form-group col-xs-12 col-sm-4 col-md-3 " style={{ marginBottom: 20 }} >
                      <label style={{ marginRight: 4 }}>Estado</label>
                      <Field
                        disabled
                        name={`state`} type="text"
                        className={'form-control' + (errors.state && touched.state ? ' is-invalid' : '')}
                      />
                      <ErrorMessage style={{ color: 'red', fontSize: 12, fontWeight: 500, letterSpacing: 0.5 }} name={`state`} component="div" className="invalid-feedback" />
                    </div>

                    <div className="form-group col-xs-12 col-sm-4 col-md-3 " style={{ marginBottom: 20 }} >
                      <label style={{ marginRight: 4 }}>Bairro</label>
                      <Field
                        name={`neighborhood`} type="text"
                        className={'form-control' + (errors.neighborhood && touched.neighborhood ? ' is-invalid' : '')}
                      />
                      <ErrorMessage style={{ color: 'red', fontSize: 12, fontWeight: 500, letterSpacing: 0.5 }} name={`neighborhood`} component="div" className="invalid-feedback" />
                    </div>

                    <div className="form-group row">

                      <Field name="numberOfPets">
                        {({ field }) => (
                          <div className="col-md-2 col-xs-12 col-sm-4">
                            <label>Número de pets*</label>
                            <select
                              {...field}
                              className={'form-control form-select' + (errors.numberOfPets && touched.numberOfPets ? ' is-invalid' : '')}
                              onChange={e => onChangeTickets(e, field, values, setValues)}
                            >
                              <option value="">Selecione...</option>
                              {[1, 2, 3, 4, 5].map(i =>
                                <option key={i} value={i}>{i}</option>
                              )}
                            </select>

                          </div>
                        )}
                      </Field>
                      <ErrorMessage name="numberOfPets" component="div" className="invalid-feedback" />
                    </div>
                  </div>
                </div>
                <FieldArray name="pets">
                  {() => (values.pets.map((ticket, i) => {
                    const ticketErrors = errors.pets?.length && errors.pets[i] || {};
                    const ticketTouched = touched.pets?.length && touched.pets[i] || {};
                    return (
                      <div key={i} className="list-group list-group-flush">
                        <div className="list-group-item">
                          <div className="form-row row">

                            <div className="form-group col-xs-12 col-sm-4 col-md-3 " style={{ marginBottom: 20 }}>
                              <label style={{ marginRight: 4 }}>Nome do animal*</label>
                              <Field

                                name={`pets.${i}.pet_name`} type="text"
                                className={'form-control' + (ticketErrors.pet_name && ticketTouched.pet_name ? ' is-invalid' : '')}
                              />
                              <ErrorMessage style={{ color: 'red', fontSize: 12, fontWeight: 500, letterSpacing: 0.5 }} name={`pets.${i}.pet_name`} component="div" className="invalid-feedback" />
                            </div>
                            <div className="form-group col-xs-12 col-sm-4 col-md-3">
                              <label style={{ marginRight: 4 }}>Apelido</label>
                              <Field

                                name={`pets.${i}.nickname`}
                                type="text"
                                className={'form-control' + (ticketErrors.nickname && ticketTouched.nickname ? ' is-invalid' : '')} />
                              <ErrorMessage
                                style={{ color: 'red', fontSize: 12, fontWeight: 500, letterSpacing: 0.5 }}
                                name={`pets.${i}.nickname`}
                                component="div"
                                className="invalid-feedback" />
                            </div>
                            <div className="form-group col-xs-12 col-sm-4 col-md-3">
                              <label style={{ marginRight: 4 }}>Sexo*</label>
                              <Field
                                name={`pets.${i}.gender`} as="select" className={'form-select' + (ticketErrors.gender && ticketTouched.gender ? ' is-invalid' : '')}>

                                <option value="">Selecione...</option>
                                <option value="M">Macho</option>
                                <option value="F">Fêmea</option>
                              </Field>
                              <ErrorMessage
                                style={{ color: 'red', fontSize: 12, fontWeight: 500, letterSpacing: 0.5 }}
                                name={`pets.${i}.gender`}
                                component="div"
                                className="invalid-feedback" />
                            </div>
                            <div className="form-group col-xs-12 col-sm-4 col-md-3">
                              <label style={{ marginRight: 4 }}>Espécie*</label>
                              <Field
                                name={`pets.${i}.specie`} as="select" className={'form-select' + (ticketErrors.specie && ticketTouched.specie ? ' is-invalid' : '')} >
                                <option value="">Selecione...</option>
                                <option value="1">Cão</option>
                                <option value="2">Gato</option>
                                <option value="3">Outro</option>
                              </Field>

                              <ErrorMessage
                                style={{ color: 'red', fontSize: 12, fontWeight: 500, letterSpacing: 0.5 }}
                                name={`pets.${i}.specie`}
                                component="div"
                                className="invalid-feedback" />
                            </div>
                            <div className="form-group col-xs-12 col-sm-4 col-md-3">
                              <label style={{ marginRight: 4 }}>Raça*</label>
                              <Field
                                name={`pets.${i}.breed`} as="select" className={'form-select' + (ticketErrors.breed && ticketTouched.breed ? ' is-invalid' : '')} >
                                <option value="">Selecione...</option>
                                {racas.map(raca => (
                                  <option key={raca.id} value={raca.id}>{raca.raca}</option>
                                ))}
                              </Field>
                              <ErrorMessage
                                style={{ color: 'red', fontSize: 12, fontWeight: 500, letterSpacing: 0.5 }}
                                name={`pets.${i}.breed`}
                                component="div"
                                className="invalid-feedback" />
                            </div>
                            <div className="form-group col-xs-12 col-sm-4 col-md-3">
                              <label style={{ marginRight: 4 }}>Cor*</label>
                              <Field

                                name={`pets.${i}.color`}
                                type="text"
                                className={'form-control' + (ticketErrors.color && ticketTouched.color ? ' is-invalid' : '')} />
                              <ErrorMessage
                                style={{ color: 'red', fontSize: 12, fontWeight: 500, letterSpacing: 0.5 }}
                                name={`pets.${i}.color`}
                                component="div"
                                className="invalid-feedback" />
                            </div>
                            <div className="form-group col-xs-12 col-sm-4 col-md-3" >
                              <label style={{ marginRight: 4 }}>Data de nascimento</label>
                              <Field
                                component={FormikDatePicker} name={`pets.${i}.birthDate`} i={i} profile={1} />

                              <ErrorMessage
                                style={{ color: 'red', fontSize: 12, fontWeight: 500, letterSpacing: 0.5 }}
                                name={`pets.${i}.birthDate`}
                                component="div"
                                className="invalid-feedback" />
                            </div>
                            <div className="form-group col-xs-12 col-sm-4 col-md-3">
                              <label style={{ marginRight: 4 }}>Treinado*</label>
                              <Field
                                name={`pets.${i}.trained`} as="select"  className={'form-select' + (ticketErrors.trained && ticketTouched.trained ? ' is-invalid' : '')}>
                                <option value="">Selecione...</option>
                                <option value="1">Sim</option>
                                <option value="0">Não</option>
                              </Field>
                              <ErrorMessage
                                style={{ color: 'red', fontSize: 12, fontWeight: 500, letterSpacing: 0.5 }}
                                name={`pets.${i}.trained`}
                                component="div"
                                className="invalid-feedback" />
                            </div>
                            <div className="form-group col-xs-12 col-sm-4 col-md-3">
                              <label style={{ marginRight: 4 }}>Castrado*</label>
                              <Field
                                name={`pets.${i}.castrated`} as="select"  className={'form-select' + (ticketErrors.castrated && ticketTouched.castrated ? ' is-invalid' : '')}>
                                <option value="">Selecione...</option>
                                <option value="1">Sim</option>
                                <option value="0">Não</option>
                              </Field>
                              <ErrorMessage
                                style={{ color: 'red', fontSize: 12, fontWeight: 500, letterSpacing: 0.5 }}
                                name={`pets.${i}.castrated`}
                                component="div"
                                className="invalid-feedback" />
                            </div>

                            <div className="form-group col-xs-12 col-sm-4 col-md-3">
                              <label style={{ marginRight: 4 }}>Peso*</label>
                              <Field
                                name={`pets.${i}.weight`}
                                type="number"
                                placeholder="5.2"
                                className={'form-control' + (ticketErrors.weight && ticketTouched.weight ? ' is-invalid' : '')} />
                              <ErrorMessage
                                style={{ color: 'red', fontSize: 12, fontWeight: 500, letterSpacing: 0.5 }}
                                name={`pets.${i}.weight`}
                                component="div"
                                className="invalid-feedback" />
                            </div>
                            <div className="form-group col-xs-12 col-sm-4 col-md-3">
                              <label style={{ marginRight: 4 }}>Porte*</label>
                              <Field
                                name={`pets.${i}.gait`} as="select" className={'form-select' + (ticketErrors.gait && ticketTouched.gait ? ' is-invalid' : '')}>
                                <option value="">Selecione...</option>
                                <option value="Mi">Mini</option>
                                <option value="P">Pequeno</option>
                                <option value="M">Médio</option>
                                <option value="G">Grande</option>
                                <option value="Gi">Gigante</option>
                              </Field>
                              <ErrorMessage
                                style={{ color: 'red', fontSize: 12, fontWeight: 500, letterSpacing: 0.5 }}
                                name={`pets.${i}.gait`}
                                component="div"
                                className='invalid-feedback' />
                            </div>
                            <div className="form-group col-xs-12 col-sm-4 col-md-3">
                              <label style={{ marginRight: 4 }}>Pelagem*</label>
                              <Field
                                name={`pets.${i}.coat`} as="select" className={'form-select' + (ticketErrors.coat && ticketTouched.coat ? ' is-invalid' : '')}>
                                <option value="">Selecione...</option>
                                <option value="C">Curto</option>
                                <option value="M">Médio</option>
                                <option value="G">Grande</option>
                              </Field>
                              <ErrorMessage
                                style={{ color: 'red', fontSize: 12, fontWeight: 500, letterSpacing: 0.5 }}
                                name={`pets.${i}.coat`}
                                component="div"
                                className='invalid-feedback' />
                            </div>
                            <div className="form-group col-xs-12 col-sm-4 col-md-3">
                              <label style={{ marginRight: 4 }}>Restrição alimentar</label>
                              <Field
                                name={`pets.${i}.mealRestriction`}
                                type="text"
                                className={'form-control' + (ticketErrors.mealRestriction && ticketTouched.mealRestriction ? ' is-invalid' : '')} />
                              <ErrorMessage
                                style={{ color: 'red', fontSize: 12, fontWeight: 500, letterSpacing: 0.5 }}
                                name={`pets.${i}.mealRestriction`}
                                component="div"
                                className="invalid-feedback" />
                            </div>
                            <div className="form-group col-xs-12 col-sm-4 col-md-3">
                              <label style={{ marginRight: 4 }}>Qual ração come</label>
                              <Field
                                name={`pets.${i}.meal`}
                                type="text"
                                className={'form-control' + (ticketErrors.meal && ticketTouched.meal ? ' is-invalid' : '')} />
                              <ErrorMessage
                                style={{ color: 'red', fontSize: 12, fontWeight: 500, letterSpacing: 0.5 }}
                                name={`pets.${i}.meal`}
                                component="div"
                                className="invalid-feedback" />
                            </div>
                            <div className="form-group col-xs-12 col-sm-4 col-md-3">
                              <label style={{ marginRight: 4 }}>Quantidade de ração</label>
                              <Field
                                name={`pets.${i}.mealAmount`}
                                type="text"
                                className={'form-control' + (ticketErrors.mealAmount && ticketTouched.mealAmount ? ' is-invalid' : '')} />
                              <ErrorMessage
                                style={{ color: 'red', fontSize: 12, fontWeight: 500, letterSpacing: 0.5 }}
                                name={`pets.${i}.mealAmount`}
                                component="div"
                                className="invalid-feedback" />
                            </div>
                            <div className="form-group col-xs-12 col-sm-4 col-md-3">
                              <label style={{ marginRight: 4 }}>Horário da alimentação</label>
                              <Field
                                name={`pets.${i}.mealTime`}
                                type="text"
                                className={'form-control' + (ticketErrors.mealTime && ticketTouched.mealTime ? ' is-invalid' : '')} />
                              <ErrorMessage
                                style={{ color: 'red', fontSize: 12, fontWeight: 500, letterSpacing: 0.5 }}
                                name={`pets.${i}.mealTime`}
                                component="div"
                                className="invalid-feedback" />
                            </div>
                            <div className="form-group col-xs-12 col-sm-4 col-md-3">
                              <label style={{ marginRight: 4 }}>Veterinário</label>
                              <Field

                                name={`pets.${i}.vetName`}
                                type="text"
                                className={'form-control' + (ticketErrors.vetName && ticketTouched.vetName ? ' is-invalid' : '')} />
                              <ErrorMessage
                                style={{ color: 'red', fontSize: 12, fontWeight: 500, letterSpacing: 0.5 }}
                                name={`pets.${i}.vetName`}
                                component="div"
                                className="invalid-feedback" />
                            </div>

                            <div className="form-group col-xs-12 col-sm-4 col-md-3 " style={{ marginBottom: 20 }} >
                              <label style={{ marginRight: 4 }}>Telefone do veterinário</label>
                              <Field
                                name={`pets.${i}.vetCel`}>
                                {({ field }) => (
                                  <MaskedInput

                                    {...field}
                                    mask={phoneNumberMask}
                                    id={`pets.${i}.vetCel`}
                                    type="text"
                                    className={'form-control' + (errors.vetCel && touched.vetCel ? ' is-invalid' : '')}
                                  />
                                )}</Field>
                            </div>
                            <div className="form-group col-xs-12 col-sm-4 col-md-3">
                              <label style={{ marginRight: 4 }}>Autoriza veicular imagem</label>
                              <Field
                                name={`pets.${i}.imageAuthorized`} as="select" className={'form-select' + (ticketErrors.imageAuthorized && ticketTouched.imageAuthorized ? ' is-invalid' : '')}>
                                <option value="">Selecione...</option>
                                <option value="1">Sim</option>
                                <option value="0">Não</option>
                              </Field>
                              <ErrorMessage
                                style={{ color: 'red', fontSize: 12, fontWeight: 500, letterSpacing: 0.5 }}
                                name={`pets.${i}.imageAuthorized`}
                                component="div"
                                className='invalid-feedback' />
                            </div>
                            <div className="form-group col-xs-12 col-sm-4 col-md-3">
                              <label style={{ marginRight: 4 }}>Observação</label>
                              <Field
                                style={{ fontSize: 16 }} name={`pets.${i}.obs`} as="textarea" className={'form-control' + (ticketErrors.obs && ticketTouched.obs ? ' is-invalid' : '')} />
                              <ErrorMessage
                                style={{ color: 'red', fontSize: 12, fontWeight: 500, letterSpacing: 0.5 }}
                                name={`pets.${i}.obs`}
                                component="div"
                                className="invalid-feedback" />
                            </div>
                            <hr style={{ marginTop: 40, marginBottom: 40 }} />
                          </div>
                        </div>
                      </div>
                    );
                  }))}
                </FieldArray>
                <div className="card-footer text-center border-top-0">
                  {loading ? (
                    <button disabled={loading} className="btn mr-1 " type="submit" variant="contained" style={{
                      margin: 10, borderColor: 'transparent', color: 'white', background: '#314cbc'
                    }} >
                      Aguarde...
                </button>
                  ) : (
                    <button disabled={loading} className="btn mr-1 " type="submit" variant="contained" style={{
                      margin: 10, borderColor: 'transparent', color: 'white', background: '#314cbc'
                    }} >
                      Enviar
                    </button>
                  )}
                  <button className="btn btn-secondary mr-1" type="reset" style={{ margin: 10 }}>Limpar</button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </MuiPickersUtilsProvider>
    </>
  )
}

export default Home;
