import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import RedirectWithStatus from './RedirectWithStatus';

import Home from '../pages/Home';
import NotFound from '../pages/NotFound';

import Success from '../pages/Success';

const Routes = () => (
  <Switch>
    <Route exact path="/" component={Home} />
    {/* <Route exact path="/" component={About} /> */}
    <Route path="/404" component={NotFound} />
    <Route path="/success" component={Success} />
    {/* <RedirectWithStatus status={400} from="/customer/:uuid" to="/404" /> */}
    <Redirect to="/404" />
  </Switch>
);

export default Routes;
